import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  isDisabled?: boolean;
  isSubmitting?: boolean;
};

export default function Button({
  children,
  className,
  isDisabled,
  isSubmitting,
  ...btnProps
}: Props) {
  return (
    <button
      type='button'
      className={classNames('btn', className, {
        disabled: isDisabled || isSubmitting,
        'is-submitting': isSubmitting,
      })}
      disabled={isSubmitting || isDisabled}
      {...btnProps}
    >
      {children}
    </button>
  );
}
