import { ValidationRule } from 'react-hook-form';

export const isEmailValidation: ValidationRule<RegExp> = {
  value: /^[\w\-_.]+@(?:[\w\-_]+\.)+[\w\-_]+$/i,
  message: 'Please enter a valid email address',
};

export const isUrlValidation: ValidationRule<RegExp> = {
  value: /^https?:\/\/(?:[\w\-_]+\.)+[a-z]+(?:\/.*)?$/i,
  message: 'Please enter a valid URL',
};
