import { HTMLProps, useEffect, useState } from 'react';
import cx from 'classnames';

import styles from './index.module.scss';

type Props = HTMLProps<HTMLDivElement> & {
  isDismissible?: boolean;
  isDisplayed?: boolean;
  type: 'info' | 'success' | 'warning' | 'danger';
};

export default function Alert({
  children,
  isDismissible = false,
  isDisplayed = true,
  type = 'info',
  ...rest
}: Props) {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setIsShown(isDisplayed);
  }, [isDisplayed]);

  if (!isShown) return null;

  return (
    <div
      className={cx('alert', `alert-${type}`, styles.alert, {
        'alert-dismissible': isDismissible,
      })}
      {...rest}
    >
      {isDismissible && (
        <button
          type='button'
          className={cx('close', styles.close)}
          onClick={() => setIsShown(false)}
          data-dismiss='alert'
          aria-label='Close'
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      )}

      {children}
    </div>
  );
}
