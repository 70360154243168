import { groupBy, sortBy } from 'lodash';
import { useMemo } from 'react';
import { PostPageRootCommentFragment } from 'src/generated';

import Comment, { Props as CommentProps, RepliesById } from './Comment';

type Props = Omit<CommentProps, 'comment' | 'depth'> & {
  comment: PostPageRootCommentFragment;
};

export default function RootComment(props: Props) {
  const { comment } = props;
  const flatReplies = comment.commentPtr?.flatRepliesList;
  const repliesById = useMemo(() => {
    if (!flatReplies) return {};
    const byId = groupBy(flatReplies, 'parentId');
    return Object.keys(byId).reduce<RepliesById>((acc, id) => {
      const replies = byId[id];
      acc[id] = sortBy(
        replies,
        (r) => r.comment?.blogCommentByCommentPtrId?.commentPtr?.createdAt,
      );
      return acc;
    }, {});
  }, [flatReplies]);
  return <Comment {...props} depth={0} repliesById={repliesById} />;
}
