import { ForwardedRef, HTMLProps, forwardRef } from 'react';

type Props = HTMLProps<HTMLTextAreaElement>;

function TextArea(
  { type = 'text', name, id = `form-input-${name || ''}`, ...rest }: Props,
  ref: ForwardedRef<HTMLTextAreaElement>,
) {
  return <textarea {...{ ref, type, name, id }} {...rest} />;
}

export default forwardRef(TextArea);
