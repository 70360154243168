import { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  BlogComment,
  BlogPost,
  HorsemancommentsComment,
  PostPageCommentsDocument,
  UsersUser,
} from 'src/generated';

import LoadMore from 'src/components/LoadMore';
import RootComment from './RootComment';
import CommentForm from './CommentForm';

import styles from './styles.module.scss';

const PAGE_SIZE = 5;

type Props = {
  post: Pick<BlogPost, 'nodePtrId' | 'title'> & {
    comments?: (Pick<BlogComment, 'commentPtrId'> & {
      commentPtr: Pick<HorsemancommentsComment, 'id' | 'body'> & {
        user: Pick<UsersUser, 'id' | 'firstName' | 'lastName'>;
      };
    })[];
  };
};

export default function Comments({ post }: Props) {
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [replyToId, setReplyToId] = useState<string | null>(null);

  const { data, fetchMore, loading } = useQuery(PostPageCommentsDocument, {
    variables: { postId: post.nodePtrId, limit },
  });

  const title = (() => {
    const numComments = data?.allComments?.totalCount || 0;
    if (numComments === 0) return 'No comments (yet!)';
    if (numComments === 1) return 'One comment';
    return `${numComments.toLocaleString()} comments`;
  })();

  return (
    <aside className={`comments-wrap ${styles['comments-wrap']}`}>
      <div id='comments' className={`comments-area ${styles.comments}`}>
        <h2 className={styles['comments-title']}>
          {title} on “{post.title}”
        </h2>

        {data?.rootComments?.nodes && (
          <ol className='comment-list'>
            {data.rootComments?.nodes.map((comment, i) => (
              <RootComment
                key={comment.commentPtrId}
                index={i}
                comment={comment}
                onReplyClick={(replyToComment) => setReplyToId(replyToComment)}
                replyToId={replyToId}
              />
            ))}
          </ol>
        )}

        {data?.rootComments?.pageInfo.hasNextPage && (
          <LoadMore
            onClick={() => {
              const currentLength = data.rootComments?.nodes?.length || 0;
              fetchMore({
                variables: { limit: PAGE_SIZE, offset: currentLength },
              })
                .then((fetchMoreResult) => {
                  const { nodes } = fetchMoreResult?.data?.rootComments || {};
                  if (nodes) {
                    setLimit(currentLength + nodes.length);
                  }
                })
                .catch(console.error);
            }}
            isSubmitting={loading}
          >
            Load more comments
          </LoadMore>
        )}

        {!replyToId && <CommentForm postId={post.nodePtrId} />}
      </div>
    </aside>
  );
}
