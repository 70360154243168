import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { captureException } from '@sentry/nextjs';
import { useApolloClient } from '@apollo/client';

import { PostPageCommentsDocument } from 'src/generated';
import { authContext } from 'src/services/auth';
import {
  DjangoError,
  PostNewCommentResult,
  handleFailure,
  postNewComment,
} from 'src/services/api';
import Input from 'src/components/Forms/Input';
import TextArea from 'src/components/Forms/TextArea';
import Alert from 'src/components/Alert';
import Button from 'src/components/Button';
import Form from 'src/components/Forms/Form';
import FormField, { register } from 'src/components/Forms/FormField';
import {
  isEmailValidation,
  isUrlValidation,
} from 'src/components/Forms/validations';

import styles from './styles.module.scss';

type FormData = {
  name: string;
  email: string;
  url?: string;
  body: string;
};

type Props = {
  onCancelClick?: () => void;
  postId: string;
  replyToId?: string;
};

export default function CommentForm({
  onCancelClick,
  postId,
  replyToId,
}: Props) {
  const client = useApolloClient();
  const { isLoggedIn, currentUser, checkAuth } = useContext(authContext);
  const onCancelClickRef = useRef(onCancelClick);

  useEffect(() => {
    checkAuth().catch(captureException);
  }, [checkAuth]);

  useEffect(() => {
    onCancelClickRef.current = onCancelClick;
  }, [onCancelClick]);

  const formContext = useForm<FormData, unknown, FormData>({
    progressive: true,
    shouldUseNativeValidation: false,
    mode: 'all',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serverError, setServerError] = useState<DjangoError[]>();
  const [successResult, setSuccessResult] = useState<PostNewCommentResult>();

  const handleValidSubmit = useCallback(
    (formData: FormData) => {
      setIsSubmitting(true);
      setSuccessResult(undefined);

      postNewComment(postId, { ...formData, parent: replyToId })
        .then(({ response }) => {
          setSuccessResult(response);
          setServerError(undefined);
          formContext.reset();
          onCancelClickRef.current?.();
          if (response.approved) {
            client
              .refetchQueries({ include: [PostPageCommentsDocument] })
              .catch(captureException);
          }
        })
        .catch((reason) => {
          handleFailure(reason, formContext.setError, setServerError);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [formContext, postId, replyToId, client, onCancelClickRef],
  );

  return (
    <div className={`comment-respond ${styles['comment-respond']}`}>
      <h3 className='comment-reply-title'>
        {replyToId ? 'Reply' : 'Leave a new response'}
      </h3>

      {replyToId && onCancelClick && (
        <small>
          <button
            type='button'
            className='btn btn-link'
            onClick={onCancelClick}
          >
            Cancel reply
          </button>
        </small>
      )}

      <Form
        formContext={formContext}
        method='post'
        className={styles['comment-form']}
        onValidSubmit={handleValidSubmit}
      >
        {!isLoggedIn ? (
          <div>
            <p className={styles['comment-notes']}>
              <span className='email-notes'>
                Your email address will not be published.
              </span>{' '}
              Required fields are marked <span className='required'>*</span>
            </p>

            <FormField
              name='name'
              label='Name'
              className='comment-form-author'
              isRequired
              renderInput={(props) => (
                <Input
                  {...register(formContext, props, { maxLength: 245 })}
                  size={30}
                />
              )}
            />

            <FormField
              name='email'
              label='Email'
              className='comment-form-email'
              aria-describedby='email-notes'
              isRequired
              renderInput={(props) => (
                <Input
                  {...register(formContext, props, {
                    pattern: isEmailValidation,
                    maxLength: 100,
                  })}
                  type='email'
                  size={30}
                />
              )}
            />

            <FormField
              name='url'
              label='Website'
              className='comment-form-url'
              renderInput={(props) => (
                <Input
                  {...register(formContext, props, {
                    pattern: isUrlValidation,
                    maxLength: 200,
                  })}
                  type='url'
                  size={30}
                />
              )}
            />
          </div>
        ) : (
          <div>Logged in{currentUser ? ` as ${currentUser.username}` : ''}</div>
        )}

        <FormField
          name='body'
          label='Comment'
          className='comment-form-comment'
          isRequired
          renderInput={(props) => (
            <TextArea
              {...register(formContext, props, { maxLength: 65525 })}
              cols={45}
              rows={8}
            />
          )}
        />

        <Alert type='success' isDisplayed={!!successResult} isDismissible>
          Thanks for your comment!
          {successResult?.approved
            ? ''
            : ' We will review your comment, and publish it shortly.'}
        </Alert>

        <Alert type='danger' isDisplayed={!!serverError?.length}>
          {serverError?.map((error, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={i}>
              <strong>Error: </strong>
              {error.message}
            </p>
          ))}
        </Alert>

        <p className={styles['form-submit']}>
          <Button
            type='submit'
            className='submit'
            isSubmitting={isSubmitting}
            isDisabled={!formContext.formState.isValid}
          >
            Post Comment
          </Button>
        </p>
      </Form>
    </div>
  );
}
