import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { parseISO } from 'date-fns';
import type { HorsemannodesNode, BlogPost } from 'src/generated';

import styles from './styles.module.scss';

type Props = {
  post: Pick<BlogPost, 'title'> & {
    nodePtr?: Pick<
      HorsemannodesNode,
      'publishedAt' | 'modifiedAt' | 'urlPath'
    > | null;
  };
  isLink?: boolean;
};

export default function Header({ post, isLink }: Props) {
  const postDateStr = post.nodePtr?.publishedAt || post.nodePtr?.modifiedAt;

  const postDate = postDateStr && parseISO(postDateStr);

  // eslint-disable-next-line react/no-danger
  const title = <span dangerouslySetInnerHTML={{ __html: post.title }} />;

  return (
    <header className={classNames('entry-header', styles['entry-header'])}>
      <h2
        itemProp='name headline'
        className={classNames('entry-title', styles['entry-title'])}
      >
        {isLink && post.nodePtr ? (
          <Link href={post.nodePtr.urlPath} passHref>
            {title}
          </Link>
        ) : (
          title
        )}
      </h2>

      {postDateStr && postDate && (
        <time
          dateTime={postDateStr}
          itemProp='datePublished'
          className={classNames('entry-date', styles['entry-date'])}
        >
          {postDate.toLocaleString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </time>
      )}
    </header>
  );
}
