import classNames from 'classnames';
import {
  PostPageCommentFragment,
  PostPageFlatReplyFragment,
} from 'src/generated';

import CommentForm from './CommentForm';

import styles from './styles.module.scss';

export type RepliesById = { [id: string]: PostPageFlatReplyFragment[] };

export type Props = {
  comment: PostPageCommentFragment;
  depth: number;
  index: number;
  onReplyClick: (commentId: string | null) => void;
  repliesById?: RepliesById;
  replyToId?: string | null;
};

export default function Comment({
  comment,
  depth,
  index,
  onReplyClick,
  repliesById,
  replyToId,
}: Props) {
  const even = index % 2 && true;
  const evenOddStr = even ? 'even' : 'odd';
  const replies = repliesById?.[comment.commentPtrId];
  return (
    <li
      id={`li-comment-${comment.commentPtrId}`}
      className={classNames(
        'comment',
        evenOddStr,
        `thread-${evenOddStr}`,
        `depth-${depth}`,
        styles.comment,
      )}
    >
      <div
        id={`comment-${comment.commentPtrId}`}
        className={styles.commentwrap}
      >
        <div className={styles['comment-info']}>
          <div className={styles['comment-byline']}>
            <span className='from'>from </span>

            <h2>
              <cite className='fn'>
                {comment.commentPtr?.author?.url ? (
                  <a
                    href={comment.commentPtr.author.url}
                    target='_blank'
                    rel='noopener noreferrer external nofollow'
                    className='url'
                  >
                    {comment.commentPtr.author.name}
                  </a>
                ) : (
                  comment.commentPtr?.author?.name
                )}
              </cite>
            </h2>

            {comment.commentPtr?.createdAt && (
              <time
                className={`comment-date commentmetadata ${styles['comment-date']}`}
                dateTime={comment.commentPtr.createdAt}
              >
                {new Date(comment.commentPtr.createdAt).toLocaleString(
                  undefined,
                  { year: 'numeric', month: 'long', day: 'numeric' },
                )}
              </time>
            )}

            <div className='comment-status' />
          </div>

          <div className={styles['comment-action']}>
            <button
              type='button'
              className={styles['comment-reply-link']}
              aria-label={
                comment.commentPtr?.author?.name
                  ? `Reply to ${comment.commentPtr?.author?.name}`
                  : 'Reply'
              }
              onClick={() => onReplyClick(comment.commentPtrId)}
            >
              <svg className='reply-icon' viewBox='0 0 18 17'>
                <use xlinkHref='#symbol-reply' />
              </svg>
            </button>
          </div>
        </div>

        <div
          className={styles['comment-body']}
          // TODO: should just render this as markdown...
        >
          {comment.commentPtr?.body}
        </div>
      </div>

      {replyToId && replyToId === comment.commentPtrId && (
        <CommentForm
          postId={comment.postId}
          replyToId={comment.commentPtrId}
          onCancelClick={() => onReplyClick(null)}
        />
      )}

      {replies?.length && (
        <ol className={`children ${styles.children}`}>
          {replies.map(
            (reply, i) =>
              reply.comment?.blogCommentByCommentPtrId && (
                <Comment
                  key={reply.id}
                  comment={reply.comment?.blogCommentByCommentPtrId}
                  repliesById={repliesById}
                  index={i}
                  depth={depth + 1}
                  onReplyClick={onReplyClick}
                  replyToId={replyToId}
                />
              ),
          )}
        </ol>
      )}
    </li>
  );
}
