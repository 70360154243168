import Link from 'next/link';
import { BlogPostSpecificPlaceFieldsFragment } from 'src/generated';
import styles from './styles.module.scss';

type Props = {
  places: BlogPostSpecificPlaceFieldsFragment[];
};

export default function Places({ places }: Props) {
  if (places.length === 0) return null;
  return (
    <div className={styles.places}>
      <strong>Places visited:</strong>

      <ul>
        {places.map((place) => {
          const { region } = place?.specificplace || {};
          return (
            <li key={place.id}>
              {place.specificplace?.name}
              {region ? ', ' : null}
              {region?.nodePtr ? (
                <Link href={region.nodePtr.urlPath} passHref>
                  {region.name}
                </Link>
              ) : (
                region?.name
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
