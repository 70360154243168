import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';

import type {
  HorsemannodesNode,
  BlogPost,
  BlogPostSpecificPlaceFieldsFragment,
  StructuredFieldBlockFragment,
} from 'src/generated';
import { site } from 'src/config/siteInfo';
import SizedImage, { SizedImageData } from 'src/components/SizedImage';
import Content from 'src/components/Content';
import StructuredField, { OnImageClick } from 'src/components/StructuredField';
import horsemanImageSizes from 'src/horsemanImageSizes';

import Header from './Header';
import Comments from './Comments';
import Places from './Places';

import styles from './styles.module.scss';

const defaultImageSizes = [
  '(min-width: 112.5em) 30.25rem', // (87.5rem - 25rem - 2rem) * .4 * 1.25
  '(min-width: 102.9em) 32.75rem', // (87.5rem - 20rem - 2rem) * .4 * 1.25
  '(min-width: 87.5em) calc((85vw - 20rem - 2rem) * .4 * 1.25)',
  '(min-width: 54em) calc((85vw - 15rem - 2rem) * .4 * 1.25)',
  '(min-width: 50em) 14.5rem', // 29rem * .4 * 1.25
  '(min-width: 43.75em) 50vw', // 40vw * 1.25
  '125vw', // 100vw * 1.25
];

type Props = {
  className?: string;
  imageSizes?: string[];
  isFeatured?: boolean;
  isLoading?: boolean;
  isSummary?: boolean;
  metaFeaturedImageUrl?: string;
  onImageClick?: OnImageClick;
  post: Pick<BlogPost, 'nodePtrId' | 'title'> &
    Partial<Pick<BlogPost, 'excerpt'>> & {
      bodyBlocksList?: StructuredFieldBlockFragment[] | null;
      featuredImage?: SizedImageData | null;
      nodePtr?: Pick<
        HorsemannodesNode,
        'publishedAt' | 'modifiedAt' | 'urlPath'
      > | null;
      blogPostSpecificPlacesByPostIdList?: BlogPostSpecificPlaceFieldsFragment[];
    };
};

export default function Post({
  className,
  imageSizes,
  isFeatured,
  isLoading,
  isSummary,
  metaFeaturedImageUrl,
  onImageClick,
  post,
}: Props) {
  return (
    <article
      itemScope
      itemType='http://schema.org/BlogPosting'
      id='single-post-content'
      className={classNames(
        'post',
        `post-${post.nodePtrId}`,
        {
          summary: isSummary,
          featured: isFeatured,
          'has-post-thumbnail': !!post.featuredImage,
          loading: isLoading,
        },
        className,
        styles.post,
      )}
    >
      {(isSummary || isFeatured) && post.featuredImage && post.nodePtr && (
        <Link
          href={post.nodePtr?.urlPath}
          passHref
          className={classNames('featured-image', styles['featured-image'])}
        >
          <SizedImage
            image={post.featuredImage}
            itemProp='image'
            size={horsemanImageSizes['BlogPost.featuredImage'].default['3x2']}
            className='attachment-post-thumbnail wp-post-image'
            sizes={imageSizes || defaultImageSizes}
            title={null}
          />
        </Link>
      )}

      <Header post={post} isLink={isSummary} />

      {(isSummary && !isFeatured) || !post.bodyBlocksList ? (
        <Content
          itemProp='articleBody'
          className={classNames('entry-content', styles['entry-content'])}
          content={post.excerpt}
        />
      ) : (
        <StructuredField
          key={post.nodePtrId}
          value={post.bodyBlocksList}
          isTextOnly={isSummary && isFeatured}
          itemProp='articleBody'
          className={classNames('entry-content', styles['entry-content'])}
          onImageClick={onImageClick}
        />
      )}

      <footer className={classNames('entry-footer', styles['entry-footer'])}>
        <div className={classNames('footer-content', styles['footer-content'])}>
          {!!post.blogPostSpecificPlacesByPostIdList?.length && (
            <Places places={post.blogPostSpecificPlacesByPostIdList} />
          )}

          {!isSummary ? <Comments post={post} /> : null}

          {isSummary && post.nodePtr ? (
            <Link
              href={post.nodePtr.urlPath}
              passHref
              className={styles['continue-reading']}
            >
              Continue reading →
            </Link>
          ) : null}
        </div>
      </footer>

      {isFeatured ? <div className={styles.fade} /> : null}

      {post.nodePtr && (
        <meta
          itemProp='url'
          content={`${site.protocol}://${site.domain}${post.nodePtr.urlPath}`}
        />
      )}
      {metaFeaturedImageUrl ? (
        <meta itemProp='image' content={metaFeaturedImageUrl} />
      ) : null}
      <span itemProp='author' itemScope itemType='http://schema.org/Person'>
        <meta itemProp='name' content='Walk &amp; Alie' />
      </span>
    </article>
  );
}
