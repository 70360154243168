import { HTMLAttributes, MouseEventHandler } from 'react';
import classNames from 'classnames';

import Button from 'src/components/Button';

import styles from './styles.module.scss';

type Props = Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> & {
  isSubmitting?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export default function LoadMore({
  children = 'Load more posts',
  onClick,
  isSubmitting = false,
  ...props
}: Props) {
  return (
    <div className={classNames('load-more', styles['load-more'])} {...props}>
      <Button onClick={onClick} isSubmitting={isSubmitting}>
        {children}
      </Button>
    </div>
  );
}
